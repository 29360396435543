import React from "react";
import './Footer.css'

function Footer() {
    return (
        <p className="Footer">
            ©上海中学和风社 199x - 2024&emsp;
            <a href="https://space.bilibili.com/1807276226" target="_blank" rel="noreferrer">Bilibili</a>
        </p>)
}

export default Footer;